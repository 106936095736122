<template>
  <div>
    <div class="top">
      <el-row :gutter="20">
        <el-col :xs="24" :sm="12">
          <div class="top-item">
            <div class="title">
              快速选择
            </div>
            <div class="data" v-for="(item,indey) in pickerOptions.shortcuts" :class="value2.toString()==item.onClick('kk').map(itey => parseTime(itey, '{y}-{m}-{d}')).toString()?'hover':''" :key="item.text" @click="clicktime(item,indey)">{{item.text}}</div>
          </div>
        </el-col>
        <el-col :xs="24" :sm="12">
          <div class="top-item">
            <div class="title">
              选择时间
            </div>
            <el-date-picker value-format="yyyy-MM-dd" @change="datack" v-model="value2" type="daterange" align="right" unlink-panels range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" :picker-options="pickerOptions">
            </el-date-picker>
          </div>
        </el-col>
      </el-row>
      <div class="devList">
        <span class="devListbox" :class="group_id ===''?'active':''" @click="tab_iot_id('')"> 全部机器</span>
        <span class="devListbox" :class="group_id ===item.id?'active':''" @click="tab_iot_id(item.id)" :key="index" v-for="(item,index) in devList">{{item.name}}</span>
        <!-- <div v-for="(item) in devList">{{item.nick_name}} </div> -->
      </div>
    </div>
    <div class="content">
      <el-row :gutter="20">
        <el-col :xs="24" :sm="6" class="one">
          <div id="echartsOne" style="height:300px;margin-top:50px"></div>
          <!-- <div class="one-time" v-if="isitem" style="margin-top:20px">总开机时间:{{total_online_time}}小时</div> -->
          <!-- <div class="one-time" v-if="isitem">总关机时间:{{total_off_time}}小时</div> -->
        </el-col>
        <el-col :xs="24" :sm="18" class="one">
          <div id="echartsTwo" style="height:400px"></div>
          <div class="one-time" v-if="isitem">白班:{{total_day_time}}小时&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;夜班:{{total_night_time}}小时</div>
        </el-col>
        <el-col :xs="24">
          <div id="echartThree" style="height:500px"></div>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
import echarts from 'echarts';
import { report, group } from "@/service/api";
import { parseTime } from '@/utlis/date.js';
// console.log(report, getMonth('fsdfsd'));
// console.log(echarts);
export default {
  data () {
    return {
      total_off_time: '',//总关机时间
      total_online_time: '', //总开机时间
      total_day_time: '',
      total_night_time: '',
      parseTime,
      index: -1,
      isitem: false,
      value2: [],
      pickerOptions: {
        shortcuts: [{
          text: '三天',
          onClick (picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 3);

            if (picker !== 'kk') {
              picker.$emit('pick', [start, end]);
              return [start, end];
            } else {
              return [start, end];
            }

          }
        }, {
          text: '一周',
          onClick (picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
            if (picker !== 'kk') {
              picker.$emit('pick', [start, end]);
              return [start, end];
            } else {
              return [start, end];
            }
          }
        }, {
          text: '半个月',
          onClick (picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 15);
            if (picker !== 'kk') {
              picker.$emit('pick', [start, end]);
              return [start, end];
            } else {
              return [start, end];
            }
          }
        }, {
          text: '一个月',
          onClick (picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
            if (picker !== 'kk') {
              picker.$emit('pick', [start, end]);
              return [start, end];
            } else {
              return [start, end];
            }
          }
        }]
      },
      echartsOne: null,
      echartsTwo: null,
      echartThree: null,
      params: {
        start_date: '',
        end_date: ''
      },
      devList: [],
      group_id: ''
    };
  },
  methods: {
    getdeviceList () {
      group({ limit: 99, page: 1 }).then(res => {

        this.devList = res.data.list;

      });
    },
    async getrquest (params) {
      const obj = { start_date: params[0], end_date: params[1], group_id: this.group_id };
      const res = await report(obj);
      this.isitem = true;
      //总开关机时间
      const startup = [{ value: res.data.total_online_time, name: '总开机时间：' + res.data.total_online_time + '小时' }, { value: res.data.total_off_time, name: '总停机时间：' + res.data.total_off_time + '小时' }];
      this.total_off_time = res.data.total_off_time;
      this.total_online_time = res.data.total_online_time;
      this.echartsOneInit(startup);
      //白班开机时间/夜班开机时间
      this.echartsTwoInit(res.data.shift_data);
      this.total_day_time = res.data.shift_data.total_day_time,
        this.total_night_time = res.data.shift_data.total_night_time,
        ////设备数据
        this.echartThreeInit(res.data.deivce_data);
      console.log(startup);
    },
    datack (time) {
      console.log(time);
      this.getrquest(time);
    },
    clicktime (item,) {
      // console.log(item.onClick(this).map(item => item.getTime()));
      this.value2 = item.onClick(this).map(item => parseTime(item, '{y}-{m}-{d}'));
      this.getrquest(this.value2);
      // this.index = index;
    },
    echartsOneInit (data) {
      //总开关机时间
      var chartDom = document.getElementById('echartsOne');
      this.echartsOne = echarts.init(chartDom);
      console.log(data);
      var option = {
        color: ['#1d843e', '#999ca5', '#657798', '#F6C022'],

        tooltip: {
          trigger: 'item',
          formatter: function (a) {
            console.log(a);
            return a.value + '小时';
            // return (Math.floor(a.value / 60) + "小时" + (a.value % 60) + "分");
          }

        },
        legend: {
          top: 'bottom'
        },
        series: [
          {
            // name: '访问来源',
            type: 'pie',
            radius: '50%',
            data,
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: 'rgba(0, 0, 0, 0.5)'
              }
            },
            itemStyle: {
              normal: {
                label: {
                  position: 'inner',
                  formatter: function (params) { return (params.percent - 0) + '%'; },
                  textStyle: {
                    color: '#000'
                  }
                }
              }
            }
          }
        ]
      };
      this.echartsOne.setOption(option);
    },
    echartsTwoInit (data) {
      console.log(data, '白班开机时间/夜班开机时间');
      ////白班开机时间/夜班开机时间
      var chartDom = document.getElementById('echartsTwo');
      this.echartsTwo = echarts.init(chartDom);
      const option = {
        tooltip: {
          trigger: 'axis'

        },
        legend: {
          data: ['白班', '夜班'],
          top: 'bottom'
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '6%',
          containLabel: true
        },
        toolbox: {
          feature: {
            saveAsImage: {}
          }
        },
        xAxis: {
          type: 'category',
          boundaryGap: false,
          data: data.days,
          axisLabel: {
            rotate: 50// 角度顺时针计算的
          }
        },
        yAxis: {
          type: 'value'

        },
        series: [
          {
            name: '白班',
            type: 'line',
            data: data.day_shift,
            label: {
              show: true,
              position: 'top'
            }
          },
          {
            name: '夜班',
            type: 'line',
            data: data.night_shift,
            label: {
              show: true,
              position: 'top'
            }
          }
        ]
      };
      this.echartsTwo.setOption(option);
    },
    echartThreeInit (data) {
      console.log(data, ' //设备数据');
      const nick_nameArr = data.nick_name.map((item, index) => {
        return [item, data.online[index], data.plan_work_time[index]];
      });
      console.log(nick_nameArr);
      console.log(data.nick_name);
      var chartDom = document.getElementById('echartThree');
      this.echartThree = echarts.init(chartDom);
      const option = {
        color: ['#1d843e', '#999ca5'],
        // legend: {},
        tooltip: {
          formatter: function (a) {
            // console.log(a);
            let index = a.dataIndex;
            // return a.value + "小时" + '(' + (a.value / (nick_nameArr[index][1] + nick_nameArr[index][2])).toFixed(2) * 1000 / 10 + '%)';
            return a.value + "小时" + '(' + (a.value / (nick_nameArr[index][2])).toFixed(2) * 1000 / 10 + '%)';
            // return (Math.floor(a.value / 60) + "小时" + (a.value % 60) + "分");
          }
        },
        // dataset: {
        //   source: nick_nameArr
        // },
        xAxis: { type: 'category', data: data.nick_name },
        yAxis: {},
        legend: {
          data: ['开机时间', '预计工作时间'],
          top: 'bottom'
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '10%',
          containLabel: true
        },
        // Declare several bar series, each will be mapped
        // to a column of dataset.source by default.
        series: [
          {
            barGap: 0,
            name: '开机时间',
            type: 'bar',
            data: data.online
          },
          {
            barGap: 0,
            name: '预计工作时间',
            type: 'bar',
            data: data.plan_work_time
          }
        ]
      };
      this.echartThree.setOption(option);
    },
    tab_iot_id (id) {
      this.group_id = id;
      const end = new Date().getTime();
      const start = new Date().getTime() - 3600 * 1000 * 24 * 30;
      this.value2 = [parseTime(start, '{y}-{m}-{d}'), parseTime(end, '{y}-{m}-{d}')];
      this.getrquest(this.value2);

    }
  },

  mounted () {
    const end = new Date().getTime();
    const start = new Date().getTime() - 3600 * 1000 * 24 * 30;
    this.value2 = [parseTime(start, '{y}-{m}-{d}'), parseTime(end, '{y}-{m}-{d}')];
    this.getrquest(this.value2);
    this.getdeviceList();
  }
};
</script>
<style scoped lang="scss">
.devList {
  width: 100%;
  margin-top: 20px;
  .active {
    background: #e6a23c;
  }
}
.devListbox {
  display: inline-block;
  font-size: 14px;
  padding: 8px 10px;
  background: #209e91;
  color: white;
  border-radius: 5px;
  margin-left: 10px;
  cursor: pointer;
  margin-top: 10px;
}
.one {
  &-time {
    text-align: center;
  }
}
.top {
  padding: 20px 40px;
  // height: 80px;
  background: #ffffff;
  // display: flex;
  // align-items: center;

  &-item {
    display: flex;
    align-items: center;
    margin-right: 50px;

    .title {
      margin-right: 10px;
      font-size: 18px;
      font-weight: 700;
    }
    .data {
      margin: 0 30px;
      padding: 10px 0;

      border-radius: 30px;
      width: 80px;
      display: flex;
      align-items: center;
      justify-content: center;
      &.hover {
        background: rgb(100, 181, 246);
      }
    }
  }
}
.elmain {
  padding: 50px;
}
@media screen and (max-width: 750px) {
  .top {
    padding: 10px;
  }
  .top-item {
    flex: 1;
  }
  .top-item .data {
    margin: 0 10px;
    width: auto;
    font-size: 12px;
    padding: 5px;
  }
}
.one-time {
  font-size: 16px;
  line-height: 24px;
}
</style>